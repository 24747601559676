<template>
  <section class="notFound">
    <div class="notFound_inner">
      <h1>Not Found</h1>
      <router-link class="navComp__btn" to="/">Back to home</router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style lang="scss" scoped>
@import "./../styles/styles.scss";

.notFound {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &_inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .btn {
    }
  }
}
</style>
